import React, { useRef, useState } from "react";
import { MdOutlineSlowMotionVideo } from "react-icons/md";

export const WaterfallProduct = ({
    id,
    isShowPhotoView,
    description,
    onItemClick,
    smallPicture,
    width,
    height,
    video,
    sourceImg,
    sourceVideo,
}) => {
    const videoRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const onMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const onMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    const onImageLoad = () => {
        setIsLoaded(true);
    };

    return (
        <>
            <div
                key={id}
                className={`img-wrap box-border border-none cursor-pointer mx-1 my-1 ${
                    isShowPhotoView ? "is-back" : ""
                }`}
                onClick={onItemClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <img
                    data-src={smallPicture}
                    width={width}
                    height={height}
                    alt=""
                    src={sourceImg}
                    onLoad={onImageLoad}
                />
                {video && (
                    <Video
                        hasPlayerIcon={isLoaded}
                        videoRef={videoRef}
                        src={sourceVideo}
                        img={sourceImg}
                    ></Video>
                )}
                {isLoaded && <Description>{description}</Description>}
            </div>
        </>
    );
};

function Video({ src, img, videoRef, hasPlayerIcon = false, ...otherProps }) {
    return (
        <>
            {hasPlayerIcon ? (
                <div className="video__player_icon flex justify-center items-center">
                    <MdOutlineSlowMotionVideo />
                </div>
            ) : null}
            <video
                {...otherProps}
                ref={videoRef}
                muted
                loop
                preload="true"
                poster={img}
            >
                <source src={src} type="video/mp4" />
            </video>
        </>
    );
}

function Description({ children }) {
    if (!children) {
        return null;
    }
    return (
        <div className="projectcard__datas">
            <p className="projectcard__quickinfo">{children}</p>
        </div>
    );
}
