import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineRight, AiOutlineLeft, AiOutlineClose } from "react-icons/ai";
import Loading from "../../../../components/Loading";
import { useThrottle } from "../../../../shared/hooks/useThrottle";
import ReactDOM from "react-dom";
import Tween from "gsap";
import "./style.scss";

const KEY_CODE = {
    LEFT: 37,
    RIGHT: 39,
    ESC: 27,
};
/**
 * 放大查看图片
 * @param {boolean} isShow 是否显示
 * @param {DOM} image 需要放大查看的图片，需传入 dom
 * @param {url} src 大图 src 不传入时取image
 * @param {number} duration 默认600
 * @param {function} onHideStart
 * @param {function} onHideEnd
 * @param {function} onShowStart
 * @param {function} onShowEnd
 */

export default function PhotoViewer({
    isShow = false,
    image = null,
    small = "",
    video = null,
    big = "",
    onNext = () => {},
    onPrev = () => {},
    onHideStart = () => {},
    onHideEnd = () => {},
    onShowStart = () => {},
    onShowEnd = () => {},
    duration = 600,
    showNext = true,
    showPrev = true,
    showClose = true,
    ease = "power2.inOut",
}) {
    const imgRef = useRef();
    const divRef = useRef();
    const showImageRef = useRef();
    const videoRef = useRef();
    const imageSrc = small || image?.src;
    const bigSrc = big;
    const visibility = isShow ? "visible" : "hidden";
    const [isAnimation, setIsAnimation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState("hidden");

    const isBigShow = isShow && !isLoading && !isAnimation;

    // 重置视频
    const wantResetVideo = useCallback(
        (src) => {
            if (videoRef.current) {
                videoRef.current.src = src || " ";
            }
        },
        [videoRef],
    );

    const hide = useCallback(() => {
        if (image) {
            setIsAnimation(true);
            setStatus("hidden");
            onHideStart();
            const { width, height, left, top } = image.getBoundingClientRect();
            Tween.to(imgRef.current, {
                width,
                height,
                left,
                top,
                duration: duration / 1000,
                ease,
                onComplete: () => {
                    onHideEnd();
                    wantResetVideo();
                    setIsAnimation(false);
                },
            });
        }
    }, [
        imgRef,
        image,
        onHideEnd,
        duration,
        onHideStart,
        ease,
        wantResetVideo,
        setStatus,
    ]);

    // 显示
    const show = useCallback(() => {
        const resetVideo = () => {
            if (image) {
                const videoWrapRef = divRef?.current;
                if (videoWrapRef) {
                    const { width, height, top, left } =
                        showImageRef?.current?.getBoundingClientRect();
                    const videoEl = videoWrapRef.querySelector("video");
                    videoEl.style.width = width + "px";
                    videoEl.style.height = height + "px";
                    videoWrapRef.style.top = top + "px";
                    videoWrapRef.style.left = left + "px";
                }
            }
            setIsLoading(false);
        };

        if (image && status === "hidden") {
            setIsAnimation(true);
            setIsLoading(true);
            setStatus("show");
            onShowStart();
            const { width, height, left, top } = image.getBoundingClientRect();
            Tween.set(imgRef.current, {
                width,
                height,
                left,
                top,
                onComplete: () => {
                    resetVideo();
                },
            });
            setTimeout(() => {
                Tween.to(imgRef.current, {
                    top: "5%",
                    left: "5%",
                    width: "90%",
                    height: "90%",
                    ease,
                    duration: duration / 1000,
                    onComplete: () => {
                        onShowEnd();
                        setIsLoading(false);
                        setIsAnimation(false);
                    },
                });
            }, 0);
        } else {
            resetVideo();
        }
    }, [imgRef, image, duration, onShowStart, onShowEnd, ease, status]);

    const _onNext = useThrottle((e) => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        onNext();
    }, 1000);

    const _onPrev = useThrottle((e) => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        onPrev();
    }, 1000);

    const nextHandle = (e) => {
        e.stopPropagation();
        _onNext();
    };

    const prevHandle = (e) => {
        e.stopPropagation();
        _onPrev();
    };

    const _onKeyDown = useThrottle((keyCode) => {
        switch (keyCode) {
            case KEY_CODE.ESC:
                hide();
                break;
            case KEY_CODE.LEFT:
                _onPrev();
                break;
            case KEY_CODE.RIGHT:
                _onNext();
                break;
            default:
                break;
        }
    }, 1000);

    const onKeyDown = useCallback(
        ({ keyCode = 0 }) => {
            _onKeyDown(keyCode);
        },
        // eslint-disable-next-line
        [hide, onPrev, onNext, _onKeyDown],
    );

    useEffect(() => {
        if (isShow) {
            show();
        }
        // 监听键盘
        //eslint-disable-next-line
    }, [isShow]);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        // 释放键盘监听
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);

    const viewer = (
        <div
            style={{
                visibility,
                zIndex: 999999,
            }}
            onClick={hide}
            className={`photo-viewer photo-viewer-${status}`}
        >
            <div className={`mask`}></div>
            <div
                ref={divRef}
                style={{
                    position: "absolute",
                    display: isAnimation ? "none" : "",
                    zIndex: 9999,
                    width: "90%",
                    left: "5%",
                    top: "5%",
                }}
            >
                <Loading
                    show={isLoading}
                    className="flex items-center justify-center loading-component"
                    style={{
                        position: "absolute",
                        zIndex: 99999,
                        width: "100%",
                        height: "100%",
                    }}
                />
                <video
                    style={{
                        objectFit: "contain",
                    }}
                    muted
                    loop
                    src={""}
                    preload="true"
                    autoPlay
                    ref={videoRef}
                    poster={imageSrc}
                ></video>
            </div>
            {isShow && (
                <img
                    style={{
                        visibility: isBigShow ? "visible" : "hidden",
                    }}
                    ref={showImageRef}
                    onLoad={() => {
                        setIsLoading(false);
                        setTimeout(() => {
                            wantResetVideo(video?.sourceVideo ?? "");
                        }, 10);
                    }}
                    alt=""
                    src={bigSrc}
                />
            )}
            <img
                alt="缩略图"
                ref={imgRef}
                style={{
                    visibility: isShow && !isBigShow ? "visible" : "hidden",
                }}
                // src={imageSrc}
                src={bigSrc}
            />
            {status === "show" && (
                <>
                    {showClose && (
                        <button className="close-btn" onClick={hide}>
                            <AiOutlineClose color="#fff" size={30} />
                        </button>
                    )}
                    {showNext && (
                        <button className="next-btn" onClick={nextHandle}>
                            <AiOutlineRight color="#fff" size={30} />
                        </button>
                    )}
                    {showPrev && (
                        <button className="prev-btn" onClick={prevHandle}>
                            <AiOutlineLeft color="#fff" size={30} />
                        </button>
                    )}
                </>
            )}
        </div>
    );
    return ReactDOM.createPortal(
        viewer,
        document.getElementsByTagName("body")[0],
    );
}
