import { useCallback, useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import { PhotoSlider } from "react-photo-view";
import ProductService from "../../services/productService";
import { createProductFullPath } from "../../shared/utils/createProductFullPath";
import { generateUniqueIntegers } from "../../shared/utils/generateUniqueIntegers";
import { getRandomGroupedArray } from "../../shared/utils/getRandomGroupedArray";

import "react-photo-view/dist/react-photo-view.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import SoarAsset from "../../shared/assets/images/soar-pro.png";

const settings = {
    dots: false,
    fade: true,
    easing: "linear",
    infinite: true,
    autoplay: true,
    speed: 2000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    waitForAnimate: false,
};

const randomNumber = () => Math.floor(Math.random() * (3500 - 2000 + 1)) + 2000;

const ImageSlider = ({
    serverImages,
    className = "",
    settings = {},
    onItemEvent,
}) => {
    const [currentSpeed, setCurrentSpeed] = useState(randomNumber());

    if (serverImages?.length === 0) {
        return null;
    }

    const sliderSettings = {
        ...settings,
        autoplaySpeed: currentSpeed, // 使用状态来控制播放时长
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSpeed(randomNumber()); // 更新新的播放时长
        },
    };

    return (
        <div className={`slider-container absolute w-full h-full ${className}`}>
            <Slider {...sliderSettings}>
                {serverImages.map((image, index) => (
                    <div
                        key={`${index}-${image.id}`}
                        className="slide-item"
                        onClick={() => {
                            onItemEvent(image.id, index);
                        }}
                    >
                        <img
                            src={image.pictureKey}
                            alt={image.title}
                            className="outline-none"
                            style={{ border: "none", outline: "none" }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

// description: "默认填充"
// id: "110"
// ossBusiness: "{\"width\":2000,\"height\":1124}"
// pictureKey: "bded6bbe60e14da7984c76f66f45603f.jpeg"
// sort: "-12"
// thumbnailUrl: "/static/thumb/bded6bbe60e14da7984c76f66f45603f.jpeg"
// title: "test"
// videoKey: ""

function PhotoViewer({
    images = [],
    index,
    visible = false,
    onClose,
    onIndexChange,
}) {
    return (
        <PhotoSlider
            loop={false}
            maskOpacity={0.8}
            speed={() => 800}
            easing={(type) =>
                type === 2
                    ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                    : "cubic-bezier(0.34, 1.56, 0.64, 1)"
            }
            images={images.map((item) => ({
                src: item.pictureKey,
                key: item.id,
            }))}
            visible={visible}
            onClose={onClose}
            index={index}
            onIndexChange={onIndexChange}
        />
    );
}

function Loading({ isLoading = false }) {
    if (!isLoading) {
        return null;
    }
    return <p className="text-slate-600 text-sm opacity-40">LOADING...</p>;
}

const App = () => {
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);

    const [gallerys, setGallerys] = useState([[], [], []]);
    const [isLoading, setIsLoading] = useState(true);
    const productService = useMemo(() => new ProductService(), []);

    const [gallerys1, gallerys2, gallerys3] = gallerys;

    const getGallerys = useCallback(async () => {
        setIsLoading(true);
        const res = await productService.getGallerys();
        if (res?.list) {
            const data = res.list.map((item) => {
                return {
                    ...item,
                    pictureKey: createProductFullPath(item.pictureKey),
                    thumbnailUrl: createProductFullPath(item.thumbnailUrl),
                };
            });
            const uniqueKeys = generateUniqueIntegers(0, data.length, 3);

            const result = uniqueKeys.map((key) => {
                return getRandomGroupedArray(data, key);
            });
            console.log("fxxk result", result);
            // debugger;
            setGallerys(result);
        }
        setIsLoading(false);
    }, [setIsLoading, setGallerys, productService]);

    const onImageSliderItemClick = useCallback(
        (id) => {
            // 随机取任意一个都行，顺序是根据 id 而来
            const index = gallerys1.findIndex((image) => image.id === id);
            if (index !== -1) {
                setIndex(index);
                setVisible(true);
            }
        },
        [gallerys1],
    );

    useEffect(() => {
        getGallerys();
    }, []);

    return (
        <div className="gallery-container h-full relative">
            <div className="soar bg-black flex justify-center pt-2">
                <img className="scale-50" src={SoarAsset} alt="" />
            </div>
            <div className="flex justify-center w-full overflow-hidden">
                <div
                    className="wall w-full flex justify-around items-center"
                    style={{ height: 864, paddingBottom: 77 }}
                >
                    <div
                        className={`gallery-item big ${
                            isLoading ? " loading" : ""
                        }`}
                    >
                        <Loading isLoading={isLoading} />
                        <ImageSlider
                            settings={{
                                ...settings,
                                autoplaySpeed: randomNumber(),
                            }}
                            serverImages={gallerys1}
                            onItemEvent={onImageSliderItemClick}
                        />
                    </div>
                    <div
                        className={`gallery-item small ${
                            isLoading ? " loading" : ""
                        }`}
                    >
                        <Loading isLoading={isLoading} />
                        <ImageSlider
                            settings={{
                                ...settings,
                                autoplaySpeed: randomNumber(),
                            }}
                            serverImages={gallerys2}
                            onItemEvent={onImageSliderItemClick}
                        />
                    </div>
                    <div
                        className={`gallery-item big ${
                            isLoading ? " loading" : ""
                        }`}
                    >
                        <Loading isLoading={isLoading} />
                        <ImageSlider
                            settings={{
                                ...settings,
                                autoplaySpeed: randomNumber(),
                            }}
                            serverImages={gallerys3}
                            onItemEvent={onImageSliderItemClick}
                        />
                    </div>
                    <PhotoViewer
                        images={gallerys1}
                        index={index}
                        visible={visible}
                        onIndexChange={setIndex}
                        onClose={() => setVisible(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default App;
